import { Controller } from "stimulus"

export default class extends Controller {

    static targets = ["code", "codeWrapper", "checkIcon"]

    addDiscount() {
        let self = this
        const urlParams = new URLSearchParams(window.location.search) // capture proxy booking if exists
        fetch(`/customers/discount?${urlParams.toString()}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.querySelector( 'meta[name="csrf-token"]' ).getAttribute("content")
            },
            body: JSON.stringify({
                code: self.codeTarget.value
            })
        })
            .then(response => {
                if(response.status == 404) {
                    self.codeWrapperTarget.classList.remove('has-icons-right')
                    self.checkIconTarget.style.display = 'none'
                    self.codeTarget.classList.remove('is-success')
                    self.codeTarget.classList.add('is-danger')
                }
                else if(response.status == 200) {
                    self.codeWrapperTarget.classList.add('has-icons-right')
                    self.checkIconTarget.style.display = 'flex'
                    self.codeTarget.classList.add('is-success')
                    self.codeTarget.classList.remove('is-danger')
                }
                response.text().then(htmlString => {
                    document.getElementById('order_details').innerHTML = htmlString
                })

            })
    }
}
