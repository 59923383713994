import { Controller } from "stimulus"

export default class extends Controller {

	static targets = ['dowCheckbox'];

	updateDowSelection() {
		let days = []
		this.dowCheckboxTargets.forEach( (e) => {
			if(e.checked) {
				days.push(e.dataset.day)
			}
		} )
		document.cookie = "dow_selection=" + JSON.stringify(days) + "; path=/"

		// don't relaod the page if we are at the category index, as we're not
		// refreshing the categories list here - just storing the user's choice in cookie
		if(!window.location.pathname.endsWith("categories")) {
			Turbolinks.visit(window.location)
		}
	}

	clearDowSelection() {
		document.cookie = "dow_selection=[]; path=/"
		// don't relaod the page if we are at the category index, as we're not
		// refreshing the categories list here - just storing the user's choice in cookie
		if(!window.location.pathname.endsWith("categories")) {
			Turbolinks.visit(window.location)
		}
	}
	
}
