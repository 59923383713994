import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "customerSelect", "nominationSelect", "submitButton" ]

	updateOnNomination() {
		let valid = this.nominationSelectTargets.every((nom) => {
			return nom.value != ""
		})
		if(valid) {
			this.submitButtonTarget.disabled = false
		} else {
			this.submitButtonTarget.disabled = true
		}
		
	}
	
	connect () {

		$('#proxy_customer_id').selectize({
			valueField: 'id',
			labelField: 'full_name',
			searchField: 'full_name',
			sortField: 'full_name',
			render: {
				option: function(item, escape) {
					return '<div>' + '<span class="name">' + escape(item.forename) + ' ' + escape(item.surname) + ' (' + escape(item.email) + ')</span>' + '</div>';
				}
			},
			load: function(query, callback) {
				if(!query.length) return callback();
				$.ajax({
					url: '/admin/customers/search?search=' + encodeURIComponent(query),
					type: 'GET',
					error: function() {
						callback();
					},
					success: function(res) {
						callback(res.slice(0, 10));
					}
				});
			}
		})
	}

}
