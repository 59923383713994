import { Controller } from "stimulus"

export default class extends Controller {

	static targets = ["surname", "forename", "email", "emailDisplay", "form", "recaptchaToken"]

	updateProposedGoogleEmail() {
		let regid = this.data.get('id')
		let add = this.forenameTarget.value.slice(0,1) + '_' + this.surnameTarget.value + regid + "@guildlifelonglearning.org"
		this.emailTarget.value = add.toLowerCase()
		this.emailDisplayTarget.value = add.toLowerCase()
	}

	submit(e) {
		let self = this
		e.preventDefault()
        grecaptcha.ready(function() {
          grecaptcha.execute('6Lf_0z0bAAAAALbERUTAx7WzQjiG4ZbV-dfmO2r8', {action: 'submit'}).then(function(token) {
			  self.recaptchaTokenTarget.value = token
			  self.formTarget.submit()
          })
        })
	}

	connect() {
		
	}
}
