import { Controller } from "stimulus"

export default class extends Controller {

	static targets = ["query", "categorygrid"];

	showCategoryGrid() {
		this.categorygridTarget.style.visibility = "visible";
		this.categorygridTarget.style.opacity = 1;
	}
	hideCategoryGrid() {
		setTimeout(() => {
			this.categorygridTarget.style.visibility = "hidden";
			this.categorygridTarget.style.opacity = 0;
		}, 100)
				  
	}

	doSearch(e) {
		if(e.key=='Enter') {
			let currentTerm = this.queryTarget.value;
			window.location = '/search?term=' + currentTerm;
	    }
	}
	
	connect() {
		this.categorygridTarget.style.visibility = "hidden";
		this.categorygridTarget.style.opacity = 0;
	}
}
