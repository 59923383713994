import { Controller } from "stimulus"

export default class extends Controller {

	static targets = ["modal"]

	closeModal(e) {
		e.preventDefault()
		this.modalTarget.classList.remove("is-active")
	}

	showModal(e) {
		let selectedDonation = document.querySelector("input[name='donation_select']:checked").value
		if(selectedDonation == "none") {
			document.getElementById('orderSubmit').disabled = true
			document.getElementById('orderSubmit').form.submit()
		} else {
			this.modalTarget.classList.add("is-active")
		}
	}
	
	connect() {
	}
}
