import { Controller } from "stimulus"

export default class extends Controller {

	static targets = ["query"]

	async doSearch(e) {
		if(e.key=='Enter') {
			let currentTerm = this.queryTarget.value;

			const response = await fetch('/courses/search.html?term=' + currentTerm);
			const html = await response.text();

			const container = document.getElementById('results-container');
			if(html == "") {
				container.innerHTML = "";
				container.style.maxHeight = "0px";
				container.style.height = "0px";
			} else {					   
				container.innerHTML = html;
				container.style.height = null;
				container.style.maxHeight = "3000px";
			}
		}
	}
	
	connect() {
	}
}
