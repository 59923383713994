import { Controller } from "stimulus"

export default class extends Controller {

	static targets = ["nominationSelect", "recipientAddress", "successIcon"]

	updateOnNomination() {
		if(this.nominationSelectTarget.value != "")
		{
			this.successIconTarget.style.visibility = "visible"
		} else {
			this.successIconTarget.style.visibility = "hidden"
		}

		if(this.nominationSelectTarget.value === 'other') {
			this.recipientAddressTarget.style.visibility = "visible"
		} else {
			this.recipientAddressTarget.style.visibility = "hidden"
		}

		
		
	}
	
	connect() {
		
	}
}
