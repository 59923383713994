import { Controller } from "stimulus"

export default class extends Controller {

	static targets = ["donationSelect", "modal", "submitButton", "form"]

	enableSubmit(e) {
		e.preventDefault()
		this.submitButtonTarget.disabled = false
		this.submitButtonTarget.innerHTML = "Continue"
	}

	closeModal(e) {
		e.preventDefault()
		this.modalTarget.classList.remove("is-active")
	}

	showModal(e) {
		e.preventDefault()
		this.modalTarget.classList.add("is-active")
	}
	
	connect() {
	}
}
