import { Controller } from "stimulus"

export default class extends Controller {

	static targets = ["form"]
	
	connect() {
		setTimeout(() => {
			document.getElementById('spinner').style.display = 'none'
			//document.getElementById('wpFrameBox').style.display = 'block'
			this.element.submit()
		}, 3000)
	}
	
}
