import { Controller } from "stimulus"

export default class extends Controller {

	static targets = ['dowCheckbox'];

	updateDowSelection() {
		let days = []
		this.dowCheckboxTargets.forEach( (e) => {
			if(e.checked) {
				days.push(e.dataset.day)
			}
		} )
		document.cookie = "dow_selection=" + JSON.stringify(days) + "; path=/"
		Turbolinks.visit(window.location)
	}

	clearDowSelection() {
		document.cookie = "dow_selection=[]; path=/"

		Turbolinks.visit(window.location)
	}

}
