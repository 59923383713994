import { Controller } from "stimulus"

export default class extends Controller {

	static targets = ["addButton", "removeButton", "quantity"]

	async addToBasket(e) {
		e.preventDefault()
		const self = this
		const response = await fetch('/basket', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-CSRF-TOKEN': document.querySelector( 'meta[name="csrf-token"]' ).getAttribute("content")
			},
			body: JSON.stringify({
				item_id: self.data.get("item-id"),
				item_type: self.data.get("item-type"),
				quantity: self.quantityTarget.value
			})
		})

		const basket = JSON.parse(await response.text())
		const basket_icon_text = document.getElementById('basket-icon-text')
		const basket_icon = document.getElementById('basket-icon')
		basket_icon_text.textContent = "Basket (" + basket.length + ")"
		basket_icon.style.visibility = "visible"
		const navbar_end = document.getElementById('navbar-end')
		navbar_end.style.display = "contents"

		// show confirmation notification
		let notification = document.createElement("div")
		notification.className = "notification toast is-art"
		notification.innerHTML = "Item added to <a href='/customers/basket'>basket</a>."
		let closeButton = document.createElement("button")
		closeButton.className = "delete"
		closeButton.addEventListener('click', () => {
			notification.parentNode.removeChild(notification)
		})
		notification.appendChild(closeButton)
		this.element.closest('.container').prepend(notification)
		setTimeout(() => {
			notification.parentNode.removeChild(notification)		
		}, 5000)
	}

	async removeFromBasket(e) {
		e.preventDefault()
		const self = this
		fetch('/basket/' + self.data.get("item-id"), {
			method: 'DELETE',
			headers: {
				'X-CSRF-TOKEN': document.querySelector( 'meta[name="csrf-token"]' ).getAttribute("content")
			}
		})
			.then(response => response.json())
			.then(data => {
				if(data.length == 0) {
					// redirect to homepage if basket is empty
					Turbolinks.visit('/')
				} else {
					Turbolinks.visit(window.location)
				}
			})
	}

	createAvailabilityRequest(e) {
		e.preventDefault()
		const wl_modal = document.querySelector('#availability-request-modal')
		const item_id = this.data.get("item-id")
		const item_type = this.data.get("item-type")
		wl_modal.setAttribute('data-item-id', item_id)
		wl_modal.setAttribute('data-item-type', item_type)
		wl_modal.classList.add('is-active')
	}

	deleteAvailabilityRequest(e) {
		e.preventDefault()
		const wl_modal = document.querySelector('#availability-request-cancel-modal')
		const item_id = this.data.get("item-id")
		const item_type = this.data.get("item-type")
		wl_modal.setAttribute('data-item-id', item_id)
		wl_modal.setAttribute('data-item-type', item_type)
		wl_modal.classList.add('is-active')
	}
	
	connect() {
		
	}
}
