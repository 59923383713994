import { Controller } from "stimulus"

export default class extends Controller {

	static targets = ["query"]

	async doSearch(e) {
		if(e.key=='Enter') {
			let currentTerm = this.queryTarget.value;
			window.location = window.location.href.split('?')[0] + '?term=' + currentTerm;
		}
	}
	
	connect() {
	}
}
